<template>
  <div class="main-content">
    <h2 class="text-align-center mb-2">
      {{ $t('menu.manage-users') }}
    </h2>

    <!-- Filters -->
    <div class="text-align-center mb-1">
      <label for="user-filter"
        class="pr-2">{{ $t('users.filter') }}</label>
      <el-input id="user-filter"
        v-model="userFilter"
        suffix-icon="fal fa-filter"
        clearable
        size="small"
        class="width-200px" />

      <label for="act-deact-switch"
        class="px-2">{{ $t('users.deactivated') }}</label>
      <el-switch id="act-deact-switch"
        v-model="showDeactivatedUsers"
        data-testid="act-deact-switch" />
    </div>

    <!-- Users Table -->
    <el-table v-loading="!usersReady"
      :element-loading-text="$t('users.loading-users')"
      :data="userList"
      :empty-text="$t('users.no-match')"
      stripe
      class="normal-wrap">
      <!-- Name Column -->
      <el-table-column :label="$t('general.name')"
        sortable
        :sort-method="caseInsensitiveNameSort">
        <template #default="{row, $index}">
          <div class="flex-row-centered"
            :data-testid="`name-row-${$index}`">
            <el-tooltip placement="top"
              :content="row.active ? $t('users.active') : $t('users.disabled')"
              :open-delay="500">
              <div class="user-status-icon"
                :class="row.active ? 'good' : 'error'" />
            </el-tooltip>
            <div :data-name="row.firstName + ' ' + row.lastName">
              {{ row.firstName }} {{ row.lastName }}
              <span v-if="row.personUid === auth.personUid">{{ $t('users.you') }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- Email Column -->
      <el-table-column prop="email"
        sortable
        :label="$t('general.email')">
        <template #default="{row, $index}">
          <span :data-email="row.email"
            :data-testid="`email-row-${$index}`">{{ row.email }}</span>
        </template>
      </el-table-column>

      <!-- Role Column -->
      <el-table-column prop="position"
        sortable
        width="150"
        :label="$t('users.position-header')">
        <template #default="{row, $index}">
          <span :data-testid="`position-row-${$index}`">{{ row.position }}</span>
        </template>
      </el-table-column>

      <!-- MFA Column -->
      <el-table-column prop="mfaEnabled"
        sortable
        width="200"
        :label="$t('users.mfa-header')">
        <template #default="{row, $index}">
          <span :data-testid="`mfa-row-${$index}`">
            <el-tooltip :content="mfaTooltip(row.mfaEnabled)">
              <span>
                <i class="fas"
                  :class="row.mfaEnabled ? 'green-icon fa-check-circle' : 'red-icon fa-times-circle'"
                  aria-hidden="true" />
                {{ mfaText(row.mfaEnabled) }}
              </span>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <!-- SSO Column -->
      <!-- This needs to be v-show, as if it's v-if the table flashes somewhat wildly on page load -->
      <el-table-column v-show="showSsoColumn"
        prop="ssoStatus"
        sortable
        align="center"
        width="190"
        :label="$t('users.sso-compatibility')">
        <template #default="{row, $index}">
          <span :data-testid="`sso-row-${$index}`">
            <el-tooltip :content="ssoStatusTooltip(row.ssoStatus)">
              <i class="fas"
                :class="ssoStatusClass(row.ssoStatus)"
                aria-hidden="true" />
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <!-- Actions Column -->
      <el-table-column align="right"
        width="130">
        <template #default="{row, $index}">
          <!-- Delete Invitation Pending User -->
          <el-button v-if="isCompanyAdmin && row.invitationPending"
            :name="`delete-${row.firstName}-${row.lastName}`"
            type="danger"
            size="mini"
            class="table-button"
            :title="$t('general.delete')"
            :data-testid="`delete-button-${$index}`"
            @click="deleteInvitedUser(row.personId)">
            <i class="fas fa-trash"
              aria-hidden="true" />
            <span class="sr-only">{{ $t('general.delete') }}</span>
          </el-button>

          <!-- View/Edit User -->
          <el-button :name="`action-${row.firstName}-${row.lastName}`"
            type="success"
            size="mini"
            class="table-button"
            :data-testid="`${actionForUser(row)}-button-${$index}`"
            :data-name="`${actionForUser(row)}-action-${row.firstName}-${row.lastName}`"
            :title="$t(`general.${ actionForUser(row) }`)"
            @click="$router.push(`/company/users/edit/${row.employmentId}`, () => {})">
            <i :class="`fas fa-${ viewOrEditIcon(row) }`"
              aria-hidden="true" />
            <span class="sr-only">{{ $t(`general.${ actionForUser(row) }`) }}</span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- New User Button -->
    <div class="text-align-center mt-2">
      <el-button type="primary"
        name="new-user"
        data-testid="new-user-button"
        @click="$router.push('/company/users/new', () => {})">
        {{ $t('users.new') }}
      </el-button>
    </div>

    <!-- View/Edit User Modal -->
    <!-- TODO: Split this into its own component plz 🙏 -->
    <el-dialog :visible="showUserDetailsForm"
      :before-close="handleClose"
      :title="userDetailsDialogTitle"
      width="750px">
      <div v-loading="modalSaving"
        :element-loading-text="$t('general.saving')">
        <div v-if="isInvitePendingUser"
          class="self-warning">
          {{ $t('users.cant-edit-invite-pending') }}
        </div>

        <div v-if="isCurrentUser"
          class="self-warning">
          {{ $t('users.logged-in-as-user') }}
          <div class="text-align-right mt-1">
            <el-button size="small"
              @click="$router.push('/user-profile', () => {})">
              {{ $t('menu.user-profile') }}
              <i class="fas fa-arrow-circle-right"
                aria-hidden="true" />
            </el-button>
          </div>
        </div>

        <el-alert v-if="showSsoInactiveWarning">
          {{ $t('company-security-settings.sso-enforced-inactive-warning') }}
        </el-alert>

        <el-form ref="userDetailsForm"
          :model="userDetailsForm"
          :rules="userDetailsRules"
          label-width="140px"
          class="show-non-editable p-2"
          :disabled="!canEditUser">
          <!-- User State -->
          <el-form-item v-if="!isNewUser"
            prop="active"
            :label="$t('users.state')">
            <el-radio-group v-model="userDetailsForm.active">
              <el-radio-button :label="true"
                data-testid="active">
                {{ $t('general.active') }}
              </el-radio-button>
              <el-radio-button :label="false"
                data-testid="inactive">
                {{ $t('general.inactive') }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <!-- First Name -->
          <el-form-item prop="firstName"
            :label="$t('general.first-name')">
            <el-input v-model="userDetailsForm.firstName"
              data-name="firstName"
              data-testid="firstName"
              autocomplete="off"
              data-demo="Biggie" />
          </el-form-item>

          <!-- Last Name -->
          <el-form-item prop="lastName"
            :label="$t('general.last-name')">
            <el-input v-model="userDetailsForm.lastName"
              data-name="lastName"
              data-testid="lastName"
              autocomplete="off"
              data-demo="Smalls" />
          </el-form-item>

          <!-- Email -->
          <el-form-item prop="email"
            :label="$t('general.email')">
            <el-input v-model.trim="userDetailsForm.email"
              data-name="email"
              type="email"
              autocomplete="off"
              data-demo="big.poppa@eastside.com"
              data-testid="email"
              :disabled="!isNewUser"
              @blur="warnIfNotSsoDomain" />
          </el-form-item>

          <!-- Phone -->
          <el-form-item prop="phone">
            <template #label>
              {{ $t('general.phone') }}
              <el-tooltip placement="top"
                :content="$t('billing-markets.phone-format')"
                :open-delay="500">
                <i class="fas fa-question-circle color-info popover-info-icon"
                  aria-hidden="true" />
              </el-tooltip>
            </template>
            <el-input v-model="userDetailsForm.phone"
              data-demo="+61 7 12341234"
              placeholder="+61 7 40309999"
              data-name="phone"
              data-testid="phone"
              autocomplete="off" />
          </el-form-item>

          <!-- Role -->
          <el-form-item prop="position"
            :label="$t('api-keys.role')">
            <el-row>
              <!--
                These roles being just static images and not semantically part of the form
                as radio buttons makes me wanna cry 😭
              -->
              <el-col v-for="role in roles"
                :key="kebabCase(role)"
                :span="6"
                class="role-container"
                role="radio"
                :data-testid="kebabCase(role)"
                :aria-checked="userDetailsForm.position === role"
                :data-name="kebabCase(role)">
                <img :src="roleIconSrc(role)"
                  :alt="$t('images.role-icon')"
                  width="100"
                  class="role-image"
                  @click="canEditUser && handleRoleChange(role)">
              </el-col>
            </el-row>
          </el-form-item>

          <!-- Role Info -->
          <el-collapse-transition>
            <div class="ml-140px">
              <h3 v-if="roles.includes(userDetailsForm.position)">
                {{ userDetailsForm.position }}
              </h3>
              <i18n v-if="roles.includes(userDetailsForm.position)"
                :path="`api-keys.${kebabCase(userDetailsForm.position)}`"
                tag="p"
                class="role-description">
                <template #note>
                  <em class="d-block mt-2">{{ $t(`api-keys.${kebabCase(userDetailsForm.position)}-note`) }}</em>
                </template>
              </i18n>
              <div class="docs-link-container">
                <img src="@/assets/images/docs-icon-rev.png"
                  :alt="$t('images.docs-icon')"
                  aria-hidden="true">
                <a href="https://docs.megaport.com/portal-admin/user-management/roles/"
                  target="_blank"
                  class="docs-link">{{ $t('users.view-user-actions') }}</a>
              </div>
            </div>
          </el-collapse-transition>
        </el-form>

        <section v-if="showMfaSection"
          class="p-2">
          <h3>{{ $t('users.mfa-long') }}</h3>
          <hr class="mt-1 pt-1">
          <div class="d-flex justify-content-space-between flex-align-center">
            <p class="reset-description">
              {{ $t('users.company-admin-reset-help-text') }}
            </p>
            <el-button class="mr-1"
              type="primary"
              size="small"
              data-testid="company-users-modal-reset-mfa-button"
              :disabled="!userDetailsForm.mfaEnabled"
              @click="resetMfa">
              {{ $t('users.reset') }}
            </el-button>
          </div>
        </section>
      </div>

      <template #footer>
        <el-button name="close-user-editor"
          data-testid="close-user-editor"
          @click="$router.push('/company/users', () => {})">
          {{ $t('general.close') }}
        </el-button>
        <template v-if="canEditUser">
          <el-button name="revert-user"
            @click="initializeForm">
            {{ $t('general.revert') }}
          </el-button>
          <el-button type="primary"
            name="save-user"
            @click="submitUserForm">
            {{ $t('general.save') }}
          </el-button>
        </template>
      </template>
    </el-dialog>

    <!-- Deactivate User Confirmation Modal -->
    <el-dialog :visible="showUserInactivateDialog"
      :title="$t('users.active-api-keys-found')"
      :show-close="false"
      width="600px">
      <p class="m-0">
        {{ $t('users.active-api-keys-inactive-if-continue') }}
      </p>

      <el-form ref="confirmInactivateForm"
        :model="confirmInactivateForm"
        :rules="confirmInactivateRules"
        class="show-non-editable p-2">
        <el-form-item prop="confirmInactivate"
          :label="$t('users.active-api-keys-input-label')">
          <el-input v-model.trim="confirmInactivateForm.confirmInactivate"
            data-name="confirm-inactivate"
            data-testid="confirm-inactivate"
            type="text"
            autocomplete="off" />
        </el-form-item>
      </el-form>

      <p class="m-0">
        {{ $t('users.active-api-keys-review') }}
      </p>

      <template #footer>
        <template v-if="canEditUser">
          <el-button name="revert-user"
            @click="showUserInactivateDialog = false">
            {{ $t('general.cancel') }}
          </el-button>
          <el-button type="primary"
            name="save-user"
            @click="submitFromInactivateDialog({ skipApiKeyPrompt: true })">
            {{ $t('general.continue') }}
          </el-button>
        </template>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { pick, kebabCase, escapeRegExp } from 'lodash'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'
import { deepClone } from '@/helpers.js'
import { PHONE_NUMBER_REGEX } from '@/validators'
import {
  ROLE_TYPE_COMPANY_ADMIN,
  ROLE_TYPE_TECHNICAL_ADMIN,
  ROLE_TYPE_TECHNICAL_CONTACT,
  ROLE_TYPE_FINANCE,
  ROLE_TYPE_FINANCIAL_CONTACT,
  ROLE_TYPE_READ_ONLY,
  SSO_ENABLED,
  SSO_OPTIONAL_CAN_LOGIN,
  SSO_ENFORCED_NO_ACCESS,
} from '@/Globals'

export default {
  data() {
    return {
      ssoConfig: {},
      apiKeys: [],
      userFilter: '',
      showUserInactivateDialog: false,
      showUserDetailsForm: false,
      showDeactivatedUsers: false,
      modalSaving: false,
      confirmInactivateForm: {
        confirmInactivate: '',
      },
      confirmInactivateRules: {
        confirmInactivate: [
          { required: true, message: this.$t('users.active-api-keys-error'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value.toLowerCase() !== 'inactivate') {
                return callback(new Error(this.$t('users.active-api-keys-error')))
              }
              return callback()
            },
            trigger: 'blur',
          },
        ],
      },
      userDetailsForm: {
        invitationPending: false,
        personUid: null,
        personId: null,
        employmentId: null,
        firstName: '',
        lastName: '',
        active: true,
        email: '',
        phone: '',
        position: null,
        mfaEnabled: false,
      },
      userDetailsRules: {
        active: { required: true, message: this.$t('validations.required', { thing: this.$t('users.state') }), trigger: 'none' },
        firstName: { required: true, message: this.$t('validations.required', { thing: this.$t('general.first-name') }), trigger: 'blur' },
        lastName: { required: true, message: this.$t('validations.required', { thing: this.$t('general.last-name') }), trigger: 'blur' },
        email: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('general.email') }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
        phone: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('billing-markets.phone-number') }), trigger: 'blur' },
          { pattern: PHONE_NUMBER_REGEX, message: this.$t('validations.phone-format'), trigger: 'blur' },
        ],
        position: { required: true, message: this.$t('validations.please-select', { thing: this.$t('users.role') }), trigger: 'change' },
      },
      isNewUser: false,
    }
  },

  computed: {
    ...mapState('Company', { company: state => state.data }),
    ...mapState('Users', ['users', 'usersReady']),
    ...mapState('Auth', ['data']),
    ...mapGetters('Users', ['getUserByPersonUid']),
    ...mapGetters('Auth', ['isDistributorAccount', 'hasAuth', 'hasFeatureFlag']),
    auth() {
      return this.data
    },

    showSsoColumn() {
      return this.ssoConfig.identityProviders?.length > 0
    },

    mfaOptionalOrEnforced() {
      return this.company.enforceMfa ? this.$t('users.enforced') : this.$t('users.optional')
    },

    showMfaSection() {
      return !this.isCurrentUser && !this.isNewUser
    },

    /* Is selected user the currently logged in user */
    isCurrentUser() {
      return this.userDetailsForm.personUid === this.auth.personUid
    },

    /* Has the selected user accepted their invitation */
    isInvitePendingUser() {
      return !!this.userDetailsForm.invitationPending
    },

    canEditUser() {
      return (!this.isCurrentUser && !this.isInvitePendingUser)
    },

    userDetailsDialogTitle() {
      if (this.isNewUser) return this.$t('users.new')

      const action = this.canEditUser ? 'editing' : 'viewing'
      return this.$t(`users.${action}-user-name-you`, {
        firstName: this.userDetailsForm.firstName,
        lastName: this.userDetailsForm.lastName,
        you: this.isCurrentUser ? this.$t('users.you') : '',
      })
    },

    selectedUser() {
      const employmentId = Number.parseInt(this.$route.params.employmentId)
      return this.companyUsers.find(u => u.employmentId === employmentId)
    },

    showSsoInactiveWarning() {
      return !this.isNewUser && this.ssoConfig.ssoEnforced && !this.selectedUser?.active
    },
    companyUsers() {
      return this.users.map(user => {
        const newUser = deepClone(user)
        if (newUser.username) {
          newUser.username = newUser.username.replace('_duplicated', '')
        }
        if (newUser.email) {
          newUser.email = newUser.email.replace('_duplicated', '')
        }
        return newUser
      })
    },
    userList() {
      let userList = null
      if (this.userFilter.length === 0) {
        userList = this.companyUsers.slice(0).sort(this.caseInsensitiveNameSort)
      } else {
        userList = this.companyUsers
          .filter(user => {
            const details = `${user.firstName} ${user.lastName} ${user.email}`
            return new RegExp(escapeRegExp(this.userFilter), 'i').test(details)
          })
          .sort(this.caseInsensitiveNameSort)
      }
      if (!this.showDeactivatedUsers) {
        return userList.filter(user => {
          return user.active
        })
      }

      return userList
    },
    roles() {
      return [
        ROLE_TYPE_COMPANY_ADMIN,
        ...!this.isDistributorAccount ? [ROLE_TYPE_TECHNICAL_ADMIN, ROLE_TYPE_TECHNICAL_CONTACT] : [],
        ROLE_TYPE_FINANCE,
        ROLE_TYPE_FINANCIAL_CONTACT,
        ...!this.isDistributorAccount ? [ROLE_TYPE_READ_ONLY] : [],
      ]
    },
    isCompanyAdmin() {
      return this.hasAuth('company_admin') || [ROLE_TYPE_COMPANY_ADMIN].includes(this.profile.position)
    },
    userHasActiveApiKeys() {
      return this.userActiveApiKeys.length > 0
    },
    userActiveApiKeys() {
      return this.apiKeys.filter(key => key.createdBy === this.userDetailsForm.personUid && key.active)
    },
  },

  watch: {
    '$route.params'() {
      this.initializeForm()
    },
    '$route.path': {
      immediate: true,
      handler() {
        this.initializeForm()
      },
    },
  },

  async created() {
    try {
      await this.getSsoConfig()
      window.mpApp.$on('changeContext', this.getSsoConfig)
    } catch (error) {
      console.error(error)
      this.notifyBad({
        title: this.$t('company-security-settings.error-fetching-sso'),
      })
    }

    try {
      this.apiKeys = await sdk.instance.apiKeys().getKeys()
    } catch (error) {
      captureSentryError(error)
    }
  },

  destroyed() {
    window.mpApp.$off('changeContext')
  },

  methods: {
    ...mapMutations('Notifications', ['notifyGood', 'notifyBad']),
    ...mapActions('Users', ['postUser', 'deleteUser', 'syncUsers']),
    kebabCase,
    async getSsoConfig() {
      this.ssoConfig = await sdk.instance.sso().config()
    },
    ssoStatusTooltip(ssoStatus) {
      if (ssoStatus === SSO_ENABLED) {
        return this.$t('users.sso-enabled-tooltip')
      } else if (ssoStatus === SSO_ENFORCED_NO_ACCESS) {
        return this.$t('users.sso-enforced-no-access-tooltip')
      } else if (ssoStatus === SSO_OPTIONAL_CAN_LOGIN) {
        return this.$t('users.sso-optional-can-login-tooltip')
      }

      return ssoStatus
    },
    ssoStatusClass(ssoStatus) {
      if (ssoStatus === SSO_ENABLED) {
        return 'green-icon fa-check-circle'
      } else if (ssoStatus === SSO_ENFORCED_NO_ACCESS) {
        return 'red-icon fa-times-circle'
      } else if (ssoStatus === SSO_OPTIONAL_CAN_LOGIN) {
        return 'orange-icon fa-question-circle'
      }
    },
    warnIfNotSsoDomain() {
      if (!this.ssoConfig.ssoEnforced) return

      const [, domain] = this.userDetailsForm.email.split('@')
      const ssoDomains = this.ssoConfig.identityProviders.map(idp => idp.domains).flat(1)
      if (!ssoDomains.includes(domain)) {
        this.$alert(this.$t('company-security-settings.new-user-non-sso-domain-warning'), { showClose: false })
      }
    },

    getUser(personUid) {
      return this.getUserByPersonUid(personUid)
    },
    handleClose() {
      this.$router.push('/company/users', () => {
        // empty function is intentional
      })
    },

    mfaText(enabled) {
      return `${this.mfaOptionalOrEnforced}/${enabled ? this.$t('users.set') : this.$t('users.not-set')}`
    },

    mfaTooltip(enabled) {
      return enabled ? this.$t('users.mfa-tooltip-set') : this.$t('users.mfa-tooltip-not-set')
    },

    actionForUser(user) {
      return (user.invitationPending || user.personUid === this.auth.personUid) ? 'view' : 'edit'
    },

    // in our current fontawesome the view icon is called eye annoyingly.
    viewOrEditIcon(user) {
      return this.actionForUser(user) === 'view' ? 'eye' : 'edit'
    },
    handleRoleChange(role) {
      this.userDetailsForm.position = role
      this.$refs.userDetailsForm.validateField('position')
    },

    caseInsensitiveNameSort(a, b) {
      const lcA = `${a.firstName?.toLowerCase() || ''} ${a.lastName?.toLowerCase() || ''}`
      const lcB = `${b.firstName?.toLowerCase() || ''} ${b.lastName?.toLowerCase() || ''}`
      if (lcA < lcB) return -1
      if (lcA > lcB) return 1
      return 0
    },
    roleIconSrc(role) {
      return `/role-images/${this.userDetailsForm.position === role ? `${kebabCase(role)}-active` : kebabCase(role)}.svg`
    },
    deleteInvitedUser(personId) {
      const loading = this.$loading({
        lock: true,
        text: this.$t('users.deleting-user'),
        background: 'rgba(255, 255, 255, 0.9)',
      })
      this.deleteUser({ personId })
        .finally(() => {
          loading.close()
        })
    },
    initializeForm() {
      if (this.$route.path.split('/')[3] === 'new') {
        this.userDetailsForm = {
          personUid: null,
          personId: null,
          employmentId: null,
          firstName: '',
          lastName: '',
          active: true,
          email: '',
          phone: '',
          position: null,
          mfaEnabled: false,
        }
        // Open modal
        this.isNewUser = true
        this.showUserDetailsForm = true

        this.$nextTick(() => {
          if (this.$refs.userDetailsForm) {
            this.$refs.userDetailsForm.clearValidate()
          }
        })
      } else if (this.$route.params.employmentId) {
        const employmentId = Number.parseInt(this.$route.params.employmentId)
        const eUser = this.companyUsers.find(u => u.employmentId === employmentId)

        if (eUser) {
          const matchingFields = ['invitationPending', 'personUid', 'personId', 'employmentId', 'firstName', 'lastName', 'active', 'email', 'phone', 'position', 'mfaEnabled']

          this.userDetailsForm = pick(eUser, matchingFields)

          if (this.userDetailsForm.email) {
            this.userDetailsForm.email = this.userDetailsForm.email.replace('_duplicated', '')
          }

          if (!this.roles.includes(this.userDetailsForm.position)) {
            const props = {
              title: this.$t('users.role-not-found'),
              message: this.$t('users.position-not-found', { position: this.userDetailsForm.position }),
              type: 'error',
              duration: 5000,
            }
            this.$notify(props)
            this.userDetailsForm.position = null
          }

          // Open modal
          this.isNewUser = false
          this.showUserDetailsForm = true

          if (this.$refs.userDetailsForm) {
            this.$refs.userDetailsForm.clearValidate()
          }
        } else {
          this.showUserDetailsForm = false

          const props = {
            title: this.$t('users.user-not-found'),
            message: this.$t('users.user-not-found-redirect', { userId: this.$route.params.employmentId }),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          this.$router.push('/company/users', () => {
            // empty function is intentional
          })
        }
      } else {
        this.showUserDetailsForm = false
      }
    },
    submitFromInactivateDialog(args) {
      this.$refs.confirmInactivateForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }

        this.showUserInactivateDialog = false
        this.submitUserForm(args)
      })
    },
    submitUserForm({ skipApiKeyPrompt = false }) {
      this.$refs.userDetailsForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }
          this.$notify(props)
          return
        }

        // Show the confirmation dialog if the user has active api keys.
        // skipApiKeyPrompt is set by the continue button on the dialog itself, to submit when the user
        // hits continue (skipping infinite dialogs).
        if (this.userHasActiveApiKeys && !this.userDetailsForm.active && !skipApiKeyPrompt) {
          this.showUserInactivateDialog = true
          return
        }

        this.modalSaving = true

        let payload
        if (this.selectedUser) {
          payload = { ...this.selectedUser, ...this.userDetailsForm }
        } else {
          payload = { ...this.userDetailsForm }
          if (!payload.employmentId) {
            delete payload.employmentId
          }
          if (!payload.personUid) {
            delete payload.personUid
          }
          if (!payload.personId) {
            delete payload.personId
          }
        }

        // This is to work around a BE bug
        // These MFA fields are only able to be updated via the new MFA specific endpoints,
        // but the BE would update via this endpoint if included in the payload
        delete payload.mfaEnabled
        delete payload.requireTotp

        this.postUser(payload)
          .then(() => {
            this.modalSaving = false
            this.$router.push('/company/users', () => {
              // empty function is intentional
            })
          })
          .catch(() => {
            this.modalSaving = false
          })
      })
    },
    async resetMfa() {
      try {
        await this.$confirm(
          this.$t('users.company-admin-reset-mfa-confirm-message', { username: this.userDetailsForm.email }),
          this.$t('users.company-admin-reset-mfa-confirm-title')
        )
      } catch {
        return
      }

      try {
        this.modalSaving = true
        await sdk.instance
          .employee(this.userDetailsForm.personId)
          .adminResetMfa()

        // Refresh user details after mfa reset
        await this.syncUsers()

        // Ensure the 'Reset' button is disabled after reset (as we dont close the modal)
        this.userDetailsForm.mfaEnabled = false

        this.notifyGood({ title: this.$t('users.reset-mfa-success') })
      } catch (error) {
        console.error(error)
        this.notifyBad({
          title: this.$t('general.request-failed'),
          message: `${this.$t('users.reset-mfa-failed')}: ${error.data?.message || this.$t('general.unknown-error')}`,
        })
      } finally {
        this.modalSaving = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.user-status-icon {
  border-radius: 50%;
  background-color: var(--color-text-regular);
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;
  &.good {
    background-color: var(--color-success);
  }
  &.error {
    background-color: var(--color-danger);
  }
}

.self-warning {
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 1rem 2rem;
  margin-bottom: 1rem;
}
.role-container {
  margin-bottom: 1.5rem;
  &:not(:first-of-type) {
    margin-left: 1.5rem;
  }

  &:nth-of-type(4) {
    margin-left: 0;
  }
}
.role-image {
  width: 100%;
  display: block;
  cursor: pointer;
}
.role-description,
.reset-description {
  font-size: 1.6rem;
}
.icon-role-docs {
  font-size: 3rem;
  color: white;
}
.docs-link-container {
  display: flex;
}
.docs-link {
  font-size: 1.6rem;
  color: #000000;
  margin: 1rem;
}

.width-200px {
  width: 200px;
}

.ml-140px {
  margin-left: 140px;
}

.fas {
  font-size: 1.2em;
}

.green-icon {
  color: green;
}

.red-icon {
  color: red;
}

.orange-icon {
  color: orange;
}

::v-deep .el-dialog__title {
  font-size: 2rem;
}
</style>
